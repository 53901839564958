@use 'bootstrap/scss/bootstrap';

@use "@ng-select/ng-select/themes/default.theme.css";

@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

*{
    // border:1px solid red;
}


/* You can add global styles to this file, and also import other style files */
$primary: #00c9fe;
$secondary: #FFFFFF;
$danger: #da125f;
$success: #364047;
$info: #D7FAFF;
$light: #F7F7F7;
$dark: #1F2944;

$link-color: $danger;
$link-hover-color: $dark;

$font-family-sans-serif: 'Open Sans';

$card-border-radius: 20px;

$border-radius: .25rem;
$border-radius-sm: .2rem;
$border-radius-lg: 0.75rem;
$border-radius-pill: 50rem;

$enable-shadows: true;
$box-shadow-sm: 0 3px 6px rgba(#ffffff, .24);


a {
    font-weight: bolder;
}

.text-tiny * {
    font-size: 8pt;
}

.text-small * {
    font-size: small;
}

.bg-dark-gradient {
    color: white;
    background: rgb(6, 8, 14);
    background: linear-gradient(270deg, rgba(6, 8, 14, 1) 0%, rgba(6, 8, 14, 1) 71%, rgba(31, 41, 68, 1) 99%);
}

.bg-bright-gradient {
    color: white;
    background: rgb(218, 18, 95);
    background: -moz-linear-gradient(45deg, rgba(218, 18, 95, 1) 0%, rgba(229, 23, 44, 1) 82%, rgba(229, 23, 44, 1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(218, 18, 95, 1) 0%, rgba(229, 23, 44, 1) 82%, rgba(229, 23, 44, 1) 100%);
    background: linear-gradient(45deg, rgba(218, 18, 95, 1) 0%, rgba(229, 23, 44, 1) 82%, rgba(229, 23, 44, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#da125f", endColorstr="#e5172c", GradientType=1);
}

.text-small{
    font-size:small;
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}

.hidden {
    display: none;
  }

  .clickable {
    cursor: pointer;
  }
  